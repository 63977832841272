import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine
} from "single-spa-layout";
import microfrontendLayout from "./microfrontend-layout.html";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ClickAnalyticsPlugin } from "@microsoft/applicationinsights-clickanalytics-js";
import data from "./ms-config.json";

// import LogRocket from "logrocket";
// LogRocket.init("nrdrcy/greenzone-gateway");

// LogRocket.identify("DanAllison", {
//   name: "Daniel Allison",
//   email: "DanAllison@example.com",

//   // Add your own custom user variables here, ie:
//   subscriptionType: "pro",
// });

import { login, handleAuthentication, user$, isTokenExpired } from "@gz/auth";

// Inside of a single-spa application, import and use a util function from a different microfrontend
// login();
if (isTokenExpired()) {
  handleAuthentication()
    .then((val) => {
      if (!val) {
        login();
      }
    })
    .catch((err) => {});
}

// logout();

// getSilentToken().then((data) => {
// });

const data2 = {
  loaders: {
    topNav: `<nav class="placeholder">Test Test Face</nav>`
  }
};

const routes = constructRoutes(microfrontendLayout, data2);
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  }
});
const layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach(registerApplication);
layoutEngine.activate();
start();

const clickPluginInstance = new ClickAnalyticsPlugin();
// Click Analytics configuration
var clickPluginConfig = {
  autoCapture: true,
  enableAutoRouteTracking: true,
  autoTrackPageVisitTime: true,
  dataTags: {
    useDefaultContentNameOrId: true,
    enableAutoRouteTracking: true
  }
};

// Application Insights Configuration
const configObj = {
  connectionString: data.MsConnectionString,
  extensions: [clickPluginInstance],
  extensionConfig: {
    [clickPluginInstance.identifier]: clickPluginConfig
  }
};

user$.subscribe((user) => {
  if (!user) {
    return;
  }

  // This is populted on the CI pipeline
  // And only ever on the production release
  // So this will not be included as part of localhost / staging
  if (configObj.connectionString) {
    (function (c, l, a, r, i, t, y) {
      c[a] =
        c[a] ||
        function () {
          (c[a].q = c[a].q || []).push(arguments);
        };
      t = l.createElement(r);
      t.async = 1;
      t.src = "https://www.clarity.ms/tag/" + i;
      y = l.getElementsByTagName(r)[0];
      y.parentNode.insertBefore(t, y);
    })(window, document, "clarity", "script", "cnj9js0u7k");

    if (window.clarity) {
      window.clarity("set", "UserName", user.name);
    }

    const initialiseLoggedInUser = (envelope) => {
      envelope.data.LoggedInUser = user.name;
      envelope.ext.user.id = user.name;
      envelope.ext.app.sesId = user.localAccountId;
    };

    const appInsights = new ApplicationInsights({ config: configObj });
    appInsights.loadAppInsights();
    appInsights.trackPageView();
    appInsights.addTelemetryInitializer(initialiseLoggedInUser);
  }
});
